import { EMAIL_REGEX } from 'constants/index';
import { MESSAGES } from 'validation/messages';
import * as yup from 'yup';

import { YupNumberOrNullFieldType } from './types';

export const createEditUserSchema = (isEdit?: boolean) => {
  return yup.object().shape({
    fullName: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
    email: yup
      .string()
      .matches(EMAIL_REGEX, MESSAGES.INCORRECT_EMAIL)
      .required(MESSAGES.REQUIRE_MESSAGE),
    profit: yup
      .number()
      .typeError(MESSAGES.REQUIRE_MESSAGE)
      .max(100, MESSAGES.MAX_NUMBER_VALUE('100'))
      .required(MESSAGES.REQUIRE_MESSAGE) as YupNumberOrNullFieldType,
    phone: yup.string().max(255, MESSAGES.MAX_LENGTH('255')).required(MESSAGES.REQUIRE_MESSAGE),
    dateOfBirth: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
    password: isEdit ? yup.string() : yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  });
};
